.stripe:nth-child(odd) {
    background-color: #f2f2f2;
}

.stripe {
    height: 40px;
    font-weight: 300;
}

.center {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
